@tailwind base;
@tailwind components;
@tailwind utilities;

.Collection .amplify-collection-search {
  /* color: rgba(222,136,101,1); */
  /* border-color: rgba(222,136,101,1); */
  /* background-color: green; */
  border: 0px;
  color: white;
  
  --amplify-components-collection-search-input-color: rgba(255,255,255,1)
  
  /* background-color: red; */
  /* width: 10px; */
  
}


.code-container .ampilfy-text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: pre-wrap;
}


.Tabs.amplify-tabs{
  border-color: white;
  color: rgba(222,136,101,1);
  /* background-color: rgba(222,136,101,1); */
}

.TabsItem.amplify-tabs-item {
  color: white;
  font-size: larger;
  /* width: 100%; */
  
}


.TabsItem.amplify-tabs-item:hover {
  color: black;
  /* border-color: rgba(222,136,101,1); */
  cursor: pointer;
  scale: 1.08;
  
}

.TabsItem.amplify-tabs-item:focus {
  color: rgba(222,136,101,1);
  border-color: rgba(222,136,101,1);
  scale: 1.08;
}

.TabsItem.amplify-tabs-item:active {
  color: rgba(222,136,101,1);
  border-color: rgba(222,136,101,1);
  /* scale: 1.08; */
  /* background-color: rgba(222,136,101,1); */
}

.TabsItem.amplify-tabs-item[data-state='active'] {
  color: black;
  border-color: black;
  }

.TabsItem.amplify-tabs-item[data-disabled] {
    width: 100%;
    /* border-color: rgba(222,136,101,1); */
    color: black;
    /* background-color: rgba(222,136,101,1); */
}


.Switch.amplify-switch-track {
  background-color: rgba(222,136,101,1);
  /* color: rgba(222,136,101,1); */
}




.CollectionCard.amplify-button  {
  background-color: white;
}

.CollectionCard.amplify-button:hover{
  background-color: white;
}

.CollectionCard.amplify-button:focus {
  background-color: white;
}

.CollectionCard.amplify-button:active {
  background-color: rgba(222,136,101,1)
}



.SignInUp.amplify-button  {
  background-color: rgba(255,255,255,.4);
}

.SignInUp.amplify-button:hover{
  background-color: rgba(255,255,255,.2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.4);
  
}

.SignInUp.amplify-button:focus {
  background-color: rgba(255,255,255,.2);
}

.SignInUp.amplify-button:active {
  background-color: rgba(255,255,255,.2);
}


.Project.amplify-button  {
  /* background-color: rgba(222,136,101,.7); */
}

.Project.amplify-button:hover{
  /* background-color: rgba(222,136,101,1); */
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.262);
  /* cursor: pointer; */
  /* transition: ease-in-out .5s; */
  /* animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards; */
}

.Project.amplify-button:focus {
  background-color: grey;
  border-color: grey;
  
}

.Project.amplify-button:active {
  /* background-color: rgba(222,136,101,1);
  border-color: rgba(222,136,101,1); */
  background-color: grey;
  border-color: grey;
  
}


.QueryInput.amplify-textfield{
  /* height: 40px; */
  box-shadow: 20px 5px 15px rgba(0, 0, 0, 0.10000000149011612);
  
}


.my-menu-trigger.amplify-button {
  width: 50px;
  height: 32px;
  
  margin-right: 5px;
  border-radius: 5px;
  /* background-color: rgba(222,136,101,.3); */
  /* color: white; */
  border-color: rgba(222,136,101,.0);
}  


.my-menu-trigger.amplify-button:hover {
  
  color: #fff;
  border-color: rgba(225,255,255,.3);
  background-color: rgba(225,255,255,.3);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.262);
  cursor: pointer;
  /* transition: ease-in-out .5s;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards; */
  
}

.my-menu-trigger.amplify-button:focus {
  background-color: rgba(225,255,255,.3);
  color: #fff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.133);
  border-color: rgba(225,255,255,.3);
}

.my-menu-trigger.amplify-button:active {
  background-color: rgba(225,255,255,.3);
  border-color: rgba(225,255,255,.3);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.133);
  color: rgba(225,255,255,.3);
}

.my-menu-content.amplify-menu-content {
border-color: rgba(242, 244, 248, 0);
box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
/* animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards; */
          
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.my-menu-content.amplify-menu-content:active {
  top: 50%;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  transition: ease-in-out 0.3s;
}

.my-menu-content .amplify-menu-content:hover {
  top: calc(50% - 2px);
  border: 0px solid rgba(242, 244, 248, 0);
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  
  cursor: pointer;
  transition: ease-in-out 0.3s;
} 


.my-menu-content .amplify-menu-content__item {
  color: black ;
}

.my-menu-content .amplify-menu-content__item:hover {
  background-color: grey;
}



.my-documentmenu-trigger.amplify-button {
  /* width: 10px;
  height: 5px; */
  
  /* margin-right: 5px;  */
  border-radius: 5px;
  background-color: white;
  /* color: white; */
  border-color: white;
}  

.my-documentmenu-trigger.amplify-button:hover {
  background-color: rgba(222,136,101,1);
  color: #fff;
  border-color: rgba(222,136,101,1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.262);
  cursor: pointer;
  /* transition: ease-in-out .5s;
  animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards; */
  
}

.my-documentmenu-trigger.amplify-button:focus {
  background-color: rgba(222,136,101,1);
  color: white;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.133);
  border-color: rgba(222,136,101,1);
}

.my-documentmenu-trigger.amplify-button:active {
  background-color: rgb(222, 136, 101, 1);
  border-color: rgba(222,136,101,1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.133);
  color: rgba(222,136,101,1);
}

.my-documentmenu-content.amplify-menu-content {
position: relative;
/* width: 30px; */
border-color: rgba(242, 244, 248, 0);
box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
/* animation: fadeInAnimation ease 1s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards; */
          
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.my-documentmenu-content.amplify-menu-content:active {
  /* top: 50%; */
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  transition: ease-in-out 0.3s;
}

.my-documentmenu-content.amplify-menu-content:hover {
  /* top: calc(50% - 2px); */
  border: 0px solid rgba(242, 244, 248, 0);
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  
  cursor: pointer;
  transition: ease-in-out 0.3s;
} 


.my-documentmenu-content .amplify-menu-content__item {
  color: black ;
  /* width: 100px; */
}

.my-documentmenu-content .amplify-menu-content__item:hover {
  background-color: rgba(222,136,101,1) ;
}
